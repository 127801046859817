<template>
  <VuePhoneNumberInput v-model="value"
                       :default-country-code="$props.country"
                       :translations="translations[$props.lang]"
                       @update="updateValue"/>
</template>

<script>
import Vue from 'vue'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

//Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.use(VuePhoneNumberInput);
export default {
  name: "PhoneInput",
  components: {
    VuePhoneNumberInput
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    country: {
      type: String,
      default: 'US'
    },
    lang: {
      type: String,
      default: 'EN'
    }
  },
  data () {
    return {
      value: '',
      translations: {
        ES: {
          countrySelectorLabel: 'Código país',
          countrySelectorError: 'Elige un país',
          phoneNumberLabel: 'Número de teléfono',
          example: 'Ejemplo :'
        },
        EN: {
          countrySelectorLabel: 'Country Code',
          countrySelectorError: 'Choose the country',
          phoneNumberLabel: 'Phone number',
          example: 'Example :'
        },
        FR: {
          countrySelectorLabel: 'Code pays',
          countrySelectorError: 'Choisir un pays',
          phoneNumberLabel: 'Numéro de téléphone',
          example: 'Exemple :'
        }
      }
    }
  },
  methods: {
    updateValue(payload) {
      let result = payload
      console.log(result)
      if(result.formattedNumber){
        this.$emit('input', result.formattedNumber);
      }

    }
  }
}
</script>

<style scoped>

</style>
