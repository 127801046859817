<template>
    <section class="suite_section">
        <b-container>
          <b-row>
                <b-col cols='12' md='' class="p-0">
                  <div class="logo">
                    <span class="badge badge-light">Suite</span><br>
                    <div class="border-bottom pb-3 mr-3">
                      <img :src="$cdn + 'logos/logo-systemico--white.svg'" alt="logo-pradek"><br />
                    </div>
                    <div class="text-center">
                      <h3 class="mt-3">¿Quieres saber más?</h3>
                      <ButtonMeetingComponent text="Agendar Demostración" variant="primary" class="mt-2" />
                    </div>
                  </div>
                </b-col>
                <b-col cols='12' md='8' class="p-0">
                    <div>
                        <div class="description"><strong>Systemico</strong>, te brinda una <b>Suite</b>, que incluye un listado de soluciones de software para pymes que desean digitalizar
                          y optimizar su negocio con procesos simples y eficientes.
                        </div>
                        <div class="pt-5">
                          <b-card-group deck>
                            <b-card
                              align="left"
                              v-for="product in products"
                              :key="product.id"
                              style="min-width:200px;border-radius: 0.5em;"
                              class="mb-3"
                            >
                              <b-card-text>
                                <b-row>
                                  <b-col md="4">
                                      <b-img :src="$cdnIcons+''+product.icons" fluid alt="image"></b-img>
                                  </b-col>
                                  <b-col md="8">
                                    <div>
                                      <a :href="product.link" class="product_title">{{product.title}}</a>
                                    </div>
                                    <div class="product_description">{{product.subtitle['es']}}</div>
                                  </b-col>
                                </b-row>
                              </b-card-text>
                            </b-card>
                          </b-card-group>
                        </div>
                    </div>
                </b-col>
          </b-row>
        </b-container>
    </section>
</template>

<script>
import ButtonMeetingComponent from "../buttons/ButtonMeetingComponent.vue";

export default {
  name: 'PradekSection',
  components: {ButtonMeetingComponent},
  data(){
    return{
      products: [
        {
          id: '01',
          icons: 'products/icon-wabot.svg',
          title: 'Wabot',
          subtitle: { es: 'Marketing y chat bot.', en: 'Marketing and chat bot.' },
          description: {es: 'Marketing por Whatsapp, Email, SMS y llamadas de manera sencilla y automatiza tu atención de canales.', en: 'Whatsapp, email, sms and call marketing with channel automation.'},
          link: '/products/wabot'
        },
        {
          id: '02',
          icons: 'products/icon-suppmail.svg',
          title: 'Suppmail',
          subtitle: {es: 'Helpdesk y ServiceDesk.', en: 'Helpdesk and ServiceDesk.'},
          description: {es: 'Simplifica la gestión de peticiones, recomendaciones, solicitudes, quejas y reclamos.', en:'Simple, agile and cool solution to organize and solve your clients requests.'},
          link: '/products/suppmail'
        },
        {
          id: '03',
          icons: 'products/icon-grumpyturtle.svg',
          title: 'Grumpyturtle',
          subtitle: {es: 'Gestión de proyectos.', en: 'Project management.' },
          description: {es: 'Requerimientos, fases, equipo de trabajo y cronogramas en el mismo lugar.', en:'Requirements, phases, team and schedule in one place.'},
          link: '/products/grumpyturtle'
        },
        {
          id: '04',
          icons: 'products/icon-focusmeet.svg',
          title: 'Focusmeet',
          subtitle: {es: 'Smart meetings.', en: 'Smart meetings.'},
          description: {es: 'Reuniones bien planificadas, con compromisos y resultados concretos.', en:'Meetings with aids to get results. '},
          link: '/products/focusmeet'
        },
        /** {
          id: "05",
          icons: "products/icon-chatter.svg",
          title: "Chatter",
          subtitle: "Chat de atención a clientes y equipo.",
          description: "Atención multicanal por chat para desplegar un contact center digital.",
          link: "/products/chatter",
        },
        {
          id: "06",
          icons: "products/icon-pugobox.svg",
          title: "PugoBox",
          subtitle: "Almacenamiento de archivos.",
          description: "Gestión documental y firma electrónica.",
          link: "/products/pugobox",
        },
        {
          id: "07",
          icons: "products/icon-penguin.svg",
          title: "PenguinStrategy",
          subtitle: "",
          description: "Impulso comercial con automatización e integración de canales.",
          link: "/products/penguinstrategy",
        },
        {
          id: "08",
          icons: "products/icon-collectio-n.png",
          title: "Collectio",
          subtitle: "",
          description: "Cobranza multicanal automatizada para asegurar la recuperación de cartera.",
          link: "/products/collectio",
        },
        {
          id: "09",
          icons: "products/icon-ducksite.svg",
          title: "Ducksites",
          subtitle: "",
          description: "Tu sitio web sin complicaciones, con notificaciones automáticas, artículos, chat y mucho más..",
          link: "/products/ducksites",
        },**/
        {
          id: '10',
          icons: 'products/icon-transpur.svg',
          title: 'Transpur',
          subtitle: {es: 'Gestión de Flota.', en: 'Fleet management.'},
          description: {es: 'Gestión de documentos, alertas de vencimientos, miembros, rutas y mucho más.', en: 'Routes, documents, due date alerts, members and more.'},
          link: '/products/transpur'
        },
        {
          id: '11',
          icons: 'products/icon-provedur.svg',
          title: 'Provedur',
          subtitle: { es: 'Gestión de proveedores.', en: 'Contractors management.'},
          description: {es: 'Actualización de documentos, ofertas, cotizaciones, notificaciones y mucho más.', en: 'Contractor Profile, opportunities, quotations, notifications and more.' },
          link: '/products/provedur'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../basic.scss";
.suite_section{
  font-family:$font-quicksand;
  text-align: justify;
  padding:2rem;
  background: $color-blue--dark;
  color:$color-white;
  .title {
    font-size:1.2rem;
    font-weight:bold;
  }
  .description{
    color:$color-white;
    font-size: 1.2rem;
  }
  .card{
    color:$color-black;
    font-size: 1rem;
    line-height: 1.1rem;
  }
  .product_title{
    font-weight:bold;
  }
}
</style>