<template>
  <div class="home">
    <HeaderSystemicoComponent :menuActive="true" lang="en" country="canada" class="header_nav__active p-0" />
    <BannerMultiProductComponent class="mt-4" height="500px" :items="banner_items"  />
    <products lang="en" currency="cad"/>
    <BannerBenefitsComponent
          title="Solutions for your small business"
          :items="benefits"
          background="#ffffff"
          textColor="#444444"
          lang="en"
    />
    <rocket lang="en"/>
    <software lang="en"/>

    <!--<services lang="en"/>-->

    <clients lang="en"/>
    <ceo lang="en"/>
    <methodology lang="en"/>
    <!-- <technologies/> -->
    <team lang="en"/>
    <allies lang="en"/>
    <news lang="en"/>
    <testimonials lang="en"/>
    <contact lang="en"/>
    <DeckComponent id="what"
                   title="Coolest technologies for your company."
                   description="We provide solutions to improve your daily business."
                   variant="blue"
                   :items="services" />
    <!--<pradek/>-->
    <footerC lang="en"/>

    <modalTranslate @changeLanguage="changeLanguage"/>
    <modalPopup />
    <!--<social />-->
  </div>
</template>

<script>
// import headerC from '@/../../systemico.ui/components/header.vue'
// import banner from '@/components/landing/banner.vue'
import products from '@/components/landing/products.vue'
import software from '@/components/landing/software.vue'
import services from '@/components/landing/services.vue'
import methodology from '@/components/landing/methodology.vue'
import rocket from '@/components/landing/rocket.vue'
import team from '@/components/landing/team.vue'
// import technologies from '@/components/landing/technologies.vue'
import BannerMultiProductComponent from '@/../../systemico.ui/components/banners/BannerMultiProductComponent.vue'
import BannerBenefitsComponent from '@/../../systemico.ui/components/banners/BannerBenefitsComponent.vue'
import ceo from '@/components/landing/ceo.vue'
import clients from '@/components/landing/clients.vue'
import news from '@/components/landing/news.vue'
import testimonials from '@/components/landing/testimonials.vue'
import allies from '@/components/landing/allies.vue'
import contact from '@/components/landing/contact.vue'
// import pradek from '@/components/landing/pradek.vue'
import footerC from '@/../../systemico.ui/components/footers/FooterSystemicoComponent.vue'
import modalTranslate from '@/components/modals/modalTranslate.vue'
import modalPopup from '@/components/modals/modalPopup.vue'
import social from '@/../../systemico.ui/components/social.vue'
import HeaderSystemicoComponent from "../../../systemico.ui/components/headers/HeaderSystemicoComponent.vue";
import DeckComponent from "../../../systemico.ui/components/cards/DeckComponent.vue";

export default {
  name: 'Home_EN_CA',
  data: function () {
    return {
      headerFixed: false,
      banner_items: [
        {
          id: 1,
          title: 'Cool software for your small business',
          subtitle: 'Technologies for canadian entrepreneurs, startups and small business.',
          image: 'https://cdn.systemico.net/systemico/web/headers/header_26.svg',
          backgroundImage: '',
          buttons: [
            { id: 1, text: 'Software for Business', variant: 'primary', link: 'https://systemico.net/products', icon:'patch-check-fill' },
            { id: 2, text: 'Development', variant: 'secondary', link: 'https://systemico.net/development', icon:'code-square' }
          ],
          note: 'We provide software for small business to improve your daily operations and team.',
          show: true
        },
        {
          id: 1,
          title: 'Develop software with python.',
          subtitle: 'smart quotations to accelerate your business.',
          image: 'https://cdn.systemico.net/proposals/web/headers/header_1.svg',
          backgroundImage: '',
          buttons: [
            { id: 1, text: 'Sign Up', variant: 'primary', link: 'https://proposals.systemico.net' },
            { id: 2, text: 'Log In', variant: 'secondary', link: 'https://proposals.systemico.net' }
          ],
          show:false
        }
      ],
      benefits: [
        { id: 1, title: 'Fanatic Support', description: 'Expert team that guarantees the fulfillment of service goals.', icon: 'https://cdn.systemico.net/systemico/icons/icon--soporte.svg' },
        { id: 2, title: 'Experience', description: '17 years in the market with mistakes and learnings that strengthened our focus, resilience and vision.', icon: 'https://cdn.systemico.net/systemico/icons/icon-code.svg' },
        { id: 3, title: 'Empathy', description: 'We understand your situation in your small business and the daily challenges for entrepreneurs. We provide software for small business to archieve goals and dreams.', icon: 'https://cdn.systemico.net/systemico/icons/icon--cool.svg' },
        { id: 4, title: 'Confidence', description: 'Our team work from different places around the globe to learn and share simple and cool software to jump our customers gaps.', icon: 'https://cdn.systemico.net/systemico/icons/icon-check--orange.svg' }
      ],
      services: [
        { id: 1, title: 'Software as a service.', description: 'Simple software to improve your processes and team. Generate cool experiences to your clients, teams and providers.', image: 'https://cdn.systemico.net/systemico/web/cards/headers/header-25.png', link: '/products', button: 'I want it', buttonIcon:'arrow-right' },
        { id: 2, title: 'Custom Development.', description: 'Development on demand for your startup, small business or company. API, Dashboards, web applications and more.', image: 'https://cdn.systemico.net/systemico/web/cards/headers/header-1.png', link: '/development', button: 'I want it', buttonIcon:'arrow-right' },
        { id: 3, title: 'Data Analytics.', description: 'Identify patterns and sources to obtain data and transform it into information to learn, improve your business and more impact.', image: 'https://cdn.systemico.net/systemico/web/cards/headers/header-21.png', link: '/development/data_analytics', button: 'I want it', buttonIcon:'arrow-right' },
        { id: 4, title: 'Code Ready.', description: 'Set options of Open Data, Plugins, Libraries, Components, Extensions, Cron and more to help your team with clean code to integrate directly.', image: 'https://cdn.systemico.net/systemico/web/cards/headers/header-3.png', link: '/code', button: 'I want it', buttonIcon:'arrow-right' }
      ]
    }
  },
  components: {
    DeckComponent,
    HeaderSystemicoComponent,
    BannerBenefitsComponent,
    BannerMultiProductComponent,
    // headerC,
    products,
    software,
    //services,
    methodology,
    rocket,
    team,
    // technologies,
    ceo,
    clients,
    news,
    testimonials,
    allies,
    contact,
    // pradek,
    footerC,
    modalTranslate,
    modalPopup,
    social
  },
  beforeMount () {
    /* if(this.$route.params.lang){
      if(this.$route.params.lang=='es' || this.$route.params.lang=='en'){
        localStorage.setItem("userLanguage", this.$route.params.lang);
        this.changeLanguage(this.$route.params.lang)
      }else{
        localStorage.setItem("userLanguage", 'en');
        this.changeLanguage('en')
      }
    }else{
      localStorage.setItem("userLanguage", 'en');
      this.changeLanguage('en')
    } */
  },
  methods: {
    headerReact: function () {
      /** const header = document.getElementsByClassName('headerUi')
      if (event.target.scrollTop > 0 && this.headerFixed === false) {
        header[0].classList.remove('headerUi--show')
        header[0].classList.add('headerUi--active')
        this.headerFixed = true
      } else if (this.headerFixed === true && event.target.scrollTop === 0) {
        header[0].classList.remove('headerUi--active')
        header[0].classList.add('headerUi--show')
        this.headerFixed = false
      }**/

      // TODO: let stateLocalStorage = localStorage.getItem("modalWePower")

      // console.log(event.target.scrollTop)

      // if (event.target.scrollTop > 2620 && window.screen.width > 768 && stateLocalStorage == null) {
      //   this.$store.commit("setModalWePower", { state: true });
      // }

      // if (event.target.scrollTop > 5200 && window.screen.width < 768 && stateLocalStorage == null) {
      //   this.$store.commit("setModalWePower", { state: true });
      // }
    },
    typing: function () {
      this.$refs.banner.traduccion()
    },
    changeLanguage: function (idioma) {
      this.$refs.header.changeLanguage(idioma)
    }
  }
}
</script>
<style>
@import "../../../systemico.ui/basic.scss";
</style>
