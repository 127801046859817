import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

const apiAxios = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
})

Vue.use(Vuex, apiAxios)

const token = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : ''
const email = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).usuario_correo : ''

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    fetchGetNotification: function ({ commit }, payload) {
      let temp = axios.defaults.baseURL;
      axios.defaults.baseURL = Vue.prototype.$app;

      payload.token = token;
      payload.email = email;

      let response = axios.post('notificaciones_campana', payload).then((response) => {
        let data = response.data;

        return data;
      })
        .catch((error) => {
          let data = {};
          data.CODE = '3';
          data.DESCRIPTION = 'Fallo al obtener los servicios';
          data.DATA = '';
          return data;
        })

        axios.defaults.baseURL = temp;
        return response;
    },

    changeStatetNotification: function ({ commit }, payload) {
      let temp = axios.defaults.baseURL;
      axios.defaults.baseURL = Vue.prototype.$app;

      payload.token = token;
      payload.email = email;
      
      console.log(payload)

      let response = axios.post('estado_notificacion_campana', payload).then((response) => {
        let data = response.data;

        return data;
      })
        .catch((error) => {
          let data = {};
          data.CODE = '3';
          data.DESCRIPTION = 'Fallo al obtener los servicios';
          data.DATA = '';
          return data;
        })

      axios.defaults.baseURL = temp;
      return response;
    },
      fetchPlanes: function({commit}, payload){
          let response = axios.post('get_service', payload).then((response) => {
              let data = response.data;

              return data;
          })
              .catch((error) => {
                  let data = {};
                  data.CODE = '3';
                  data.DESCRIPTION = 'Fallo al obtener los servicios';
                  data.DATA = '';
                  return data;
              })
          return response;
      },
      fetchPaises: function ({commit}, payload) {
          let response = axios.post('/gogoals/get_countries', payload).then((response) => {
              let data = response.data;

              return data;
          })
              .catch((error) => {
                  let data = {};
                  data.CODE = '3';
                  data.DESCRIPTION = 'Fallo al obtener los paises';
                  data.DATA = '';
                  return data;
              })
          return response;
      },
      cotizar: function({commit}, payload){
          let tem = axios.defaults.baseURL
          if (process.env.NODE_ENV === 'production') {
              axios.defaults.baseURL = 'http://localhost:5001/modules/';
          }else{
              axios.defaults.baseURL = 'http://localhost:5001/modules/';
          }
          let urlproposal = axios.defaults.baseURL
          let response = axios.post('quotation/create', payload).then((response) => {
              let data = response.data;
              if(data!== undefined && data!=''){
                  window.open(urlproposal+'/qotation/show/download/quote_'+data+'.pdf', '_blank')
              }
              return data;
          })
              .catch((error) => {
                  let data = {};
                  data.CODE = '3';
                  data.DESCRIPTION = 'Fallo al obtener los paises';
                  data.DATA = '';
                  return data;
              })
          return response;
      }
  },
  modules: {
  },
  getters: {
  }
})
