<template>
  <section class="headerUi">
    <b-container fluid="xl">
      <!--<div class="contenedor-recomendaciones" v-if="$parent.showRecommendations">
        <button @click="openRecommendations()">
          <img src="../assets/icons/megaphone-white.svg" alt="" />
          <p>Recomendaciones</p>
        </button>
      </div>-->

      <b-row>
        <b-col cols="12" lg="2">
          <div class="d-flex align-items-center">
            <img
              class="headerUi__logo"
              :src="$cdn + 'logos/logo-systemico--white-2.svg'"
              alt="logo systemico"
              @click="
                info_usuario != null ? redirect('/dashboard') : redirect('/')
              "
            />
            <img
              class="headerUi__icon"
              :src="$cdn + 'logos/logo-systemico--white-2.svg'"
              alt="logo systemico"
              @click="
                info_usuario != null ? redirect('/dashboard') : redirect('/')
              "
            />

            <button class="headerUi__btn" @click="showMenu()">
              <b-icon icon="list"></b-icon>
            </button>
          </div>
        </b-col>

        <b-col cols="12" lg="10">
          <nav class="headerUi__nav">
            <template v-for="(item, index) in items">
              <a
                class="item"
                :key="index"
                :href="item.link"
                rel="noopener noreferrer"
                @mouseover="showSubMenu(item)"
                @mouseout="closeSubMenu()"
                v-if="menuActive"
                >{{item.name}}</a>
            </template>

            <div class="translate" v-if="menuActive && $props.showLang && $props.showLang==true">
              <button class="btn-translate" v-on:click="showTranslate()">
                <b-icon icon="globe"></b-icon>
                <span v-show="language == 'en'">en</span>
                <span v-show="language == 'es'">es</span>
              </button>

              <div
                class="options-translate"
                v-show="stateTranslate"
                @mouseleave="closeTranslate()"
              >
                <!--<a
                  @click="changeLanguage('en')"
                  href="javascript:void(0);"
                  rel="noopener noreferrer"
                  >English</a
                >
                <a
                  @click="changeLanguage('es')"
                  href="javascript:void(0);"
                  rel="noopener noreferrer"
                  >Español</a
                >-->
                <a
                    href=""
                    rel="noopener noreferrer"
                >English</a
                >
                <a
                    href="es"
                    rel="noopener noreferrer"
                >Español</a
                >
              </div>
            </div>

            <template v-if="info_usuario == null">
              <buttonCalendar :config="config1" @clicked="agendar()" />
              <buttonAccess :config="config2" @clicked="redirect('/login')" />
            </template>

            <template v-else>
              <!-- <buttonCustom :config="config3" @clicked="cerrarSesion" /> -->
              <div class="bell" @mouseleave="closeNotifications()">
                <button class="bell__icon" v-on:click="showNotifications()">
                  <b-icon icon="bell"></b-icon>
                  <span v-if="notifications.length">{{ notifications.length ? notifications.length : 0 }}</span>
                </button>
                <div class="bell__notifications" v-show="stateNotifications">
                  <p
                    v-for="(notification, index) in notifications"
                    :key="index"
                    v-on:click="changeStateNotificarion(notification.id)"
                  >
                    <strong>{{ notification.titulo }}</strong> <br />
                    {{ notification.contenido }}
                  </p>
                </div>
              </div>
              <div class="user">
                <div class="user__description">
                  <p>{{ info_usuario.usuario_nombre }}</p>
                  <span>{{ info_usuario.usuario_correo }}</span>
                </div>
                <div class="user__options">
                  <img
                    :src="info_usuario.usuario_foto"
                    onerror="this.src='https://cdn.systemico.net/systemico/icons/icon-person.svg'"
                    alt="Imagen de perfil"
                    @click="showProfile()"
                  />
                  <ul @mouseleave="hideProfile()">
                    <li>
                      <a href="/">Website</a>
                    </li>

                    <li>
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" @click="cerrarSesion"
                        >Salir</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </nav>
        </b-col>
      </b-row>
    </b-container>

    <nav
      class="headerUi__menu"
      @mouseover="showSubMenu2()"
      @mouseleave="closeSubMenu()"
    >
      <button class="btn-close" @click="closeSubMenu">
        <b-icon icon="x-circle"></b-icon>
      </button>

      <template v-for="(item, index) in items">

        <h2 v-show="menu_active == item.name" :key="index + '-title'">
          {{ item.name }}
        </h2>

        <b-container
          v-show="menu_active == item.name"
          :key="index + '-container'"
          fluid
        >
          <b-row>
            <b-col cols="12" md="12">
              <div class="menu_box_title" :id="'header_box_menu_'+index" v-if="index==0">
                <h3>
                  Soluciones integrales e integrables.
                </h3>
                <p>
                  Software, hardware, expertos y metodologías alineadas a tu sector empresarial.
                </p>
              </div>
              <div class="menu_box_title" :id="'header_box_menu_'+index" v-if="index==1">
                <h3>
                  Software en la nube para tu empresa.
                </h3>
                <p>
                    Soluciones que simplifican tu vida, ahorrando costos, simplificando procesos y acelerando tu gestión.
                </p>
              </div>
              <div class="menu_box_title" :id="'header_box_menu_'+index" v-if="index==2">
                <h3>
                  Software a la medida de tus necesidades y presupuesto.
                </h3>
                <p>
                  Desarrollo, pruebas y puesta en marcha de plataformas web, web services y dashboards de negocios.
                </p>
              </div>
              <div class="menu_box_title" :id="'header_box_menu_'+index" v-if="index==3">
                <h3>
                  Interactua de manera sencilla y directa.
                </h3>
                <p>
                  Escríbenos por Whatsapp, SMS, agenda una reunión o accede a nuestra web.
                </p>
              </div>
              <div class="menu_box_title" :id="'header_box_menu_'+index" v-if="index==4">
                <h3>
                  Aprende más de nuestro modelo y experiencia.
                </h3>
                <p>
                  Verifica lo que necesites para tener claridad de quienes somos y como trabajamos.
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <template v-for="(subitem, subindex) in item.itemsMenu">
              <b-col cols="12" md="6" :key="subindex + '-container'">
                <button @click="redirect(subitem.link, subitem.target)">
                  <img
                    v-if="subitem.icons !== ''"
                    :src="$cdn + 'icons/' + subitem.icons"
                    alt="icon card"
                  />
                  <div>
                    <h3 :id="subitem.id + 'title'">{{ subitem.title }}</h3>
                    <p :id="subitem.id + 'description'" style="color:#777777;">
                      {{ subitem.description }}
                    </p>
                  </div>
                </button>
              </b-col>
            </template>
          </b-row>
        </b-container>
      </template>
    </nav>
  </section>
</template>

<script>
import buttonCustom from "./buttonCustom.vue";
import store from "../store/index.js";
import buttonCalendar from "./buttonCalendar.vue";
import buttonAccess from "./buttonAccess.vue";

export default {
  name: "headerC",

  components: {
    buttonCustom,
    buttonCalendar,
    buttonAccess
  },

  props: {
    menuActive: Boolean,
    showLang: Boolean
  },

  data: function () {
    return {
      items: [
        {
        name: "Industrias",
        link: "/#idustries",
        itemsMenu:
            [
              {
                  id: "12",
                  icons: "icon-transport.svg",
                  title: "Empresas de Transporte",
                  description: "Digitaliza, automatiza y moderniza tu negocio de transporte.",
                  link: "/products/transpur",
              },
              /**{
                  id: "12",
                  icons: "target.svg",
                  title: "Automatizar llamadas",
                  description: "Optimiza tu contact center programando llamadas automáticas.",
                  link: "/sms",
              },
              {
                  id: "12",
                  icons: "target.svg",
                  title: "Bots de atención",
                  description: "Atiende clientes de manera automática en Whatsapp y Facebook.",
                  link: "/sms",
              },**/
            ]
        },
        {
          name: "Productos",
          link: "/#products",
          itemsMenu: [
            {
              id: "01",
              icons: "products/icon-wabot.svg",
              title: "Wabot",
              subtitle:"Notificaciones masivas y Bot.",
              description: "Automatiza y genera campañas por Whatsapp, Email y SMS de manera sencilla.",
              link: "/products/wabot",
            },
            {
              id: "02",
              icons: "products/icon-suppmail.svg",
              title: "Suppmail",
              subtitle:"Peticiones, quejas y reclamos.",
              description: "Simplifica la gestión de peticiones, recomendaciones, solicitudes, quejas y reclamos.",
              link: "/products/suppmail",
            },
            {
              id: "03",
              icons: "products/icon-grumpyturtle.svg",
              title: "Grumpyturtle",
              subtitle: "Gestión de Proyectos.",
              description: "Proyectos claros, enfocados que integran a todo tu equipo para cumplir tu estrategia.",
              link: "/products/grumpyturtle",
            },
            {
              id: "04",
              icons: "products/icon-focusmeet.svg",
              title: "Focusmeet",
              subtitle: "Aseguramiento de compromisos.",
              description: "Reuniones bien planificadas, con compromisos y resultados concretos.",
              link: "/products/focusmeet",
            },
            /**{
              id: "05",
              icons: "products/icon-chatter.svg",
              title: "Chatter",
              subtitle: "Chat de atención a clientes y equipo.",
              description: "Atención multicanal por chat para desplegar un contact center digital.",
              link: "/products/chatter",
            },
            {
              id: "06",
              icons: "products/icon-pugobox.svg",
              title: "PugoBox",
              subtitle: "Almacenamiento de archivos.",
              description: "Gestión documental y firma electrónica.",
              link: "/products/pugobox",
            },
            {
              id: "07",
              icons: "products/icon-penguin.svg",
              title: "PenguinStrategy",
              subtitle: "",
              description: "Impulso comercial con automatización e integración de canales.",
              link: "/products/penguinstrategy",
            },
            {
              id: "08",
              icons: "products/icon-collectio-n.png",
              title: "Collectio",
              subtitle: "",
              description: "Cobranza multicanal automatizada para asegurar la recuperación de cartera.",
              link: "/products/collectio",
            },
            {
              id: "09",
              icons: "products/icon-ducksite.svg",
              title: "Ducksites",
              subtitle: "",
              description: "Tu sitio web sin complicaciones, con notificaciones automáticas, artículos, chat y mucho más..",
              link: "/products/ducksites",
            },**/
            {
              id: "10",
              icons: "products/icon-transpur.svg",
              title: "Transpur",
              subtitle: "",
              description: "Gestión detallada para empresas de transporte, control de flota, asociados, facturación electrónica.",
              link: "/products/transpur",
            },
            {
              id: "11",
              icons: "products/icon-provedur.svg",
              title: "Provedur",
              subtitle: "",
              description: "Dinamiza tu gestión de compras y contratación de proveedores con una solución innovadora y totalmente en la nube.",
              link: "/products/provedur",
            }
          ],
        },
        {
          name: "Desarrollo de Software",
          link: "/#services",
          itemsMenu: [
            {
              id: "13",
              icons: "icon-requirements.svg",
              title: "Análisis de Requerimientos",
              subtitle: "",
              description: "Análisis detallado de necesidades y soluciones",
              link: "/service_requirements",
            },
            {
              id: "14",
              icons: "icon-design.svg",
              title: "Diseño y Modelado de Software",
              subtitle: "",
              description: "Arquitectura de software y prototipado",
              link: "/service_design",
            },
            {
              id: "15",
              icons: "icon-code.svg",
              title: "Desarrollo de Software",
              subtitle: "",
              description: "Metodologías ágiles, estándares e integración",
              link: "/service_developing",
            },
            {
              id: "16",
              icons: "icon-software.svg",
              title: "Pruebas de Software",
              subtitle: "",
              description: "Validaciones y cumplimiento funcional del software",
              link: "/service_testing",
            },
            {
              id: "17",
              icons: "icon--soporte.svg",
              title: "Soporte por Demanda",
              description: "Soporte TI a tu medida por horas, con expertos disponibles para tí.",
              link: "/service_support",
            }
          ],
        },
        {
          name: "Contacto",
          // link: 'https://systemico.net/support/registro',
          link: "/#contact",
          itemsMenu: [
            // { icons: '', title: 'Soporte y Soluciones', description: 'Solicitudes y atención inmediata', link: 'https://systemico.net/support' },
            {
              id: "26",
              icons: "icon-whatsapp.svg",
              title: "WhatsApp Business",
              subtitle: "",
              description: "Interactua por nuestro canal de Whatsapp y recibe apoyo.",
              link: "https://wa.me/13149883020?text=Hola, quisiera saber más!",
              target: "_blank"
            },
            {
              id: "27",
              icons: "icon-messenger-blue.svg",
              title: "Facebook Messenger",
              subtitle: "",
              description: "Interactua por Facebook Messenger y recibe atención inmediata.",
              link: "https://m.me/systemico",
              target: "_blank"
            },
            {
              id: "28",
              icons: "icon--soporte.svg",
              title: "Solicitar Soporte",
              subtitle: "",
              description: "¿Eres cliente?, remite tu solicitud por nuestra plataforma de soporte.",
              link: "/support",
            },
            {
              id: "29",
              icons: "icon--cotizacion.svg",
              title: "Solicitud Directa",
              subtitle: "",
              description: "¿No eres cliente?, Registra una solicitud directa.",
              link: "https://suppmail.systemico.net/customer/systemicosoftware.com",
            }
          ]
        },
        {
            name: "Más",
            link: "/#software",
            itemsMenu:[
              {
                id: "17",
                icons: "icon-systemico.svg",
                title: "Sobre Nosotros",
                subtitle: "",
                description: "Somos aliados estratégicos en tecnología",
                link: "/company_about",
              },
              {
                id: "18",
                icons: "",
                title: "Metodología de Trabajo",
                subtitle: "",
                description: "Consultoria, desarrollo y resultados efectivos",
                link: "/company_methodology",
              },
              {
                id: "19",
                icons: "",
                title: "Nuestra Historia",
                subtitle: "",
                description: "Cambiar el mundo positivamente",
                link: "/company_history",
              },
              {
                id: "20",
                icons: "",
                title: "Aliados",
                subtitle: "",
                description: "Socios para mejorar la eficiencia tecnológica",
                link: "/company_clients",
              },
              {
                id: "21",
                icons: "",
                title: "Inversores",
                subtitle: "",
                description: "Systemico y la perspectiva de crecimiento global",
                link: "/company_investors",
              },
              {
                id: "22",
                icons: "",
                title: "Conoce nuestro Equipo",
                subtitle: "",
                description: "Juntos lo hacemos posible",
                link: "/company_team",
              },
              {
                id: "23",
                icons: "",
                title: "Trabaja con nosotros",
                subtitle: "",
                description: "Únete a nuestro equipo de trabajo",
                link: "/company_work",
              },
              {
                id: "24",
                icons: "",
                title: "Casos de Estudio",
                subtitle: "",
                description:
                  "Ve los casos de éxito y el resultado con datos reales.",
                link: "https://systemico.net/support",
              },
              {
                id: "25",
                icons: "",
                title: "¿Por qué ser nuestro cliente?",
                subtitle: "",
                description: "Te ayudamos a impulsar tu negocio con tecnología",
                link: "https://systemico.net/support",
              }]
        }
      ],

      config1: { text: "Agendar Reunión", class: "primary" },

      config2: { text: "Mi Cuenta", class: "secundary" },

      config3: { text: "Salir", class: "primary" },

      menu_title: "",
      menu_list: [],
      menu_active: "",

      language: "",

      info_usuario: null,

      stateTranslate: false,

      stateNotifications: false,

      notifications: {},
    };
  },

  mounted() {
    let language = localStorage.getItem("userLanguage")
    if (language === null && navigator.language.substring(0, 2)!== "es" && navigator.language.substring(0, 2)!== "en") {
      this.changeLanguage("en")
      localStorage.setItem('requestLanguage', 'true')
    }
    else if(language === null && (navigator.language.substring(0, 2)=== "es" || navigator.language.substring(0, 2)=== "en")) {
      localStorage.setItem('userLanguage', navigator.language.substring(0, 2))
      this.language = navigator.language.substring(0, 2)
    }
    else{
      this.language = localStorage.getItem("userLanguage");
    }

    let userInfo = localStorage.getItem("userInfo");

    if (userInfo != null) {
      this.info_usuario = JSON.parse(localStorage.getItem("userInfo"));
      this.info_usuario.foto = this.info_usuario.foto.replace(/&amp;/g, '&');
      this.loadNotifications();
    }
  },

  methods: {
    loadNotifications: async function () {
      await store
        .dispatch("fetchGetNotification", {})
        .then((response) => {
          if (response.CODE != 1 && response.CODE != 6) {
            this.$store.commit("setNotification", {
              state: true,
              title: "Error",
              text: response.DESCRIPTION,
            });
          } else {
            this.notifications = response.DATA;
          }
        });
    },

    changeStateNotificarion: async function (id) {
      let parametros = {
        notificacion_id: id
      };

      await store.dispatch('changeStatetNotification', parametros).then(response => {
        console.log(response)
        this.loadNotifications();
        this.closeNotifications();
      });
    },

    showMenu: function () {
      const header = document.getElementsByClassName("headerUi");
      header[0].classList.toggle("headerUi--show");
      // this.menuActive = !this.menuActive;
    },

    showSubMenu: function (item) {
      const menu = document.getElementsByClassName("headerUi__menu");
      if (menu[0].classList == "headerUi__menu") {
        menu[0].classList.toggle("headerUi__menu--show");
        this.menu_title = item.name;
        this.menu_list = item.itemsMenu;
        this.menu_active = item.name;
      } else if (
        menu[0].classList == "headerUi__menu headerUi__menu--show" &&
        this.menu_active != item.name
      ) {
        this.menu_title = item.name;
        this.menu_list = item.itemsMenu;
        this.menu_active = item.name;
      } else if (
        menu[0].classList == "headerUi__menu headerUi__menu--show" &&
        this.menu_active == item.name
      ) {
        this.closeSubMenu();
      }
    },

    showSubMenu2: function () {
      const menu = document.getElementsByClassName("headerUi__menu");
      menu[0].classList.add("headerUi__menu--show");
    },

    closeSubMenu: function () {
      const menu = document.getElementsByClassName("headerUi__menu");
      menu[0].classList.remove("headerUi__menu--show");
    },

    sinContenido: function () {
      const pathname = location.pathname;

      if (pathname === "/enConstruccion") {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } else {
        this.$router.push({
          name: "enConstruccion",
        });
      }
    },

    redirect: function (link, target='') {
      if(target==''){
        window.location = link;
      }
      else{
        window.open(link, '_blank');
      }
    },
    agendar: function(){
      Calendly.initPopupWidget({url: 'https://calendly.com/systemico/30min'});return false;
    },
    showTranslate: function () {
      this.stateTranslate = true;
    },

    closeTranslate: function () {
      this.stateTranslate = false;
    },

    changeLanguage: function (idioma) {
      localStorage.setItem("userLanguage", idioma);

      this.language = idioma;

      Transifex.live.translateTo(idioma, true);

      this.closeTranslate();
    },

    showNotifications: function () {
      this.stateNotifications = !this.stateNotifications;
    },

    closeNotifications: function () {
      this.stateNotifications = false;
    },

    cerrarSesion: function () {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("hischat");
      //localStorage.removeItem("updateLanguage");
      window.location.href = "/api/glogin/logout";
    },

    showProfile: function () {
      let content = document.getElementsByClassName("user");
      content[0].classList.add("user--show");
    },

    hideProfile: function () {
      let content = document.getElementsByClassName("user");
      content[0].classList.remove("user--show");
    },

    openRecommendations: function () {
      let element = document.getElementById("recommendationsCustom");
      element.classList.add("d-block");
    },
  },
};
</script>

<style lang="scss" scoped>

.headerUi {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.3s;
  background-color: #171e44;
  color:#fefefe;

  &--active {
    position: fixed;
    top: 0rem;
    background-color: #171e44;

    .headerUi__logo {
      display: none;
      height: 38px;
    }

    .headerUi__icon {
      display: block;
      height: 38px;
    }

    .headerUi__btn {
      border-color: #ffffff;
      svg {
        color: #ff8028;
      }
    }

    .headerUi__nav {
      a {
        color: #ffffff;

        &:hover {
          color: #ff8028;
        }
      }
    }

    .translate {
      .btn-translate {
        svg {
          color: #ffffff;
        }
      }
    }

    .user {
      padding: 0;
      border-radius: 0;
      background: transparent;
      // &__description {
      //   p {
      //     color: #ffffff;
      //   }

      //   span {
      //     color: #ffffff;
      //   }
      // }
    }

    .bell {
      &__icon {
        svg {
          color: #ffffff;
        }
      }
    }
  }

  &--show {
    position: relative;
    top: 0rem;
    background-color: #171e44;

    .headerUi__logo {
      display: none;
    }

    .headerUi__icon {
      display: block;
    }

    .headerUi__btn {
      border-color: #ffffff;
      svg {
        color: #ff8028;
      }
    }

    .headerUi__nav {
      height: auto;
      overflow: inherit;

      a {
        color: #ffffff;
      }
    }

    .translate {
      .btn-translate {
        svg {
          color: #ffffff;
        }
      }
    }

    .bell {
      &__icon {
        svg {
          color: #ffffff;
        }
      }
    }
  }

  &__logo {
    width: 18rem;
    margin: 4rem 0 2rem;
    display: block;
    cursor: pointer;
  }

  &__icon {
    margin: 2rem 0;
    display: none;
    cursor: pointer;
  }

  &__btn {
    width: 3.5rem;
    min-width: 3.5rem;
    height: 3.5rem;
    margin-left: auto;
    border: 0.2rem solid #171e44;
    border-radius: 0.5rem;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 2.5rem;
      color: #171e44;
    }
  }

  &__nav {
    height: 0;
    overflow: hidden;

    .item {
      margin: 0 2rem 2rem;
      font-family: "Quicksand", sans-serif;
      font-size: 1.5rem;
      font-weight: bold;
      color: #171e44;
      line-height: 1.5rem;
      text-align: center;
      display: block;

      &:hover {
        color: #ff8028;
        text-decoration: none;
      }
    }

    & > button {
      margin: 2rem auto;
    }
  }

  &__menu {
    height: 0;
    background-color: #f8fafb;
    overflow: hidden;
    position: relative;

    &--show {
      width: 100vw;
      height: 100vh;
      padding: 4rem 1rem;
      position: fixed;
      top: 0;
      left: 0;
      overflow: auto;
    }

    .btn-close {
      width: 2rem;
      min-width: 2rem;
      height: 2rem;
      padding: 0;
      position: relative;

      &:hover {
        svg {
          color: #ff8028;
        }
      }

      svg {
        font-size: 2rem;
        color: #171e44;
      }
    }

    button {
      width: 100%;
      padding: 1rem;
      border: 0;
      background: transparent;
      display: flex;

      &:hover {
        h3 {
          color: #006df0;
        }
      }

      img {
        max-width: 3rem;
        margin-right: 2rem;
      }
    }

    h2 {
      margin: 0 0 1rem 0;
      font-family: "Quicksand", sans-serif;
      font-size: 2rem;
      font-weight: bold;
      color: #ff8028;
      line-height: 1.5rem;
      text-align: center;
    }

    h3 {
      margin: 0 0 1rem 0;
      font-family: "Quicksand", sans-serif;
      font-size: 1.3rem;
      font-weight: bold;
      color: #171e44;
      line-height: 1.5rem;
      text-align: center;
    }

    p {
      margin: 0;
      font-family: "Quicksand", sans-serif;
      font-size: 1.3rem;
      font-weight: normal;
      color: #171e44;
      line-height: 1.5rem;
      text-align: center;
    }
  }
  .menu_box_title{
    border-bottom:3px solid #aaaaaa;
    margin-bottom:1em;
    padding-bottom:1em;
    h3{
      font-size:2.5em;
      color:#fd7e14;
      font-family: "Roboto", sans-serif;
    }
    p{
      font-size:1.5em;
    }
  }
  .translate {
    margin: 2rem auto;
    position: relative;

    &.show {
      .btn-translate {
        svg {
          color: #ff8028;
        }
      }
    }
    &.show {
      .options-translate {
        height: auto;
      }
    }

    // responsive para dispositivos de tablets en adelante
    @media (min-width: 992px) {
      margin: 0;
    }
  }

  .btn-translate {
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    margin: 0 auto;
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    svg {
      font-size: 2rem;
      color: #171e44;
      pointer-events: none;
    }

    span {
      padding: 0 0.4rem;
      border-radius: 3rem;
      background-color: #171e44;
      font-family: "Mulish", sans-serif;
      font-size: 1rem;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      text-transform: uppercase;
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
    }

    &:hover,
    &.show {
      svg {
        color: #ff8028;
      }
    }

    // responsive para dispositivos de tablets en adelante
    @media (min-width: 992px) {
      margin: 0;
    }
  }

  .options-translate {
    a {
      margin: 2rem 0;
      font-family: "Quicksand", sans-serif;
      font-size: 1.3rem;
      font-weight: bold;
      color: #ffffff;
      line-height: 1.5rem;
      text-align: center;
      display: block;
      text-transform: uppercase;
    }

    // responsive para dispositivos de tablets en adelante
    @media (min-width: 992px) {
      min-width: 10rem;
      border-radius: 0.5rem;
      background-color: #ffffff;
      box-shadow: 0px 3px 6px #4d576f29;
      position: absolute;
      top: 3rem;
      right: 0;

      a {
        margin: 0;
        padding: 1rem;
        font-family: "Mulish", sans-serif;
        font-size: 1.4rem;
        font-weight: lighter;
        color: #171e44 !important;
        line-height: 1.5rem;
        text-align: left;
        display: block;
        text-transform: none;

        &:hover {
          text-decoration: none;
          background-color: #e9ecf4;
        }
      }
    }
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    &--active {
      padding: 0;
    }

    &--show {
      .headerUi__nav {
        height: 100%;
      }
    }

    &__btn {
      display: none;
    }

    &__nav {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      overflow: inherit;

      & > button {
        margin: 0 1rem;
      }

      & > :last-child {
        margin-right: 0;
      }

      .item {
        height: 100%;
        margin: 0 2rem;
        display: flex;
        align-items: center;
      }
    }

    &__menu {
      &--show {
        width: 100%;
        height: auto;
        padding: 2rem 0;
        border-bottom: 0.2rem solid #171e44;
        position: absolute;
        top: 100%;
      }

      .btn-close {
        position: absolute;
        right: 20vw;
        z-index: 10;
      }

      h2 {
        display: none;
      }

      h3,
      p {
        text-align: left;
      }

      .container-fluid {
        max-width: 85rem;
      }
    }
  }
}

.headerUi .user {
  margin-left: 2rem;
  padding: 1rem;
  border-radius: 0 1rem;
  background-color: #171e44;
  display: flex;
  align-items: center;

  &__description {
    padding: 0 1rem;

    p {
      margin: 0;
      font-family: "Mulish", sans-serif;
      font-size: 1.4rem;
      font-weight: 500;
      // color: #171e44;
      color: #ffffff;
      text-align: right;
      display: block;
    }

    span {
      margin: 0;
      font-family: "Mulish", sans-serif;
      font-size: 1.2rem;
      font-weight: normal;
      color: #ffffff;
      text-align: right;
      display: block;
    }
  }

  &__options {
    position: relative;

    & > img {
      width: 3.6rem;
      min-width: 3.6rem;
      height: 3.6rem;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
    }

    ul {
      width: 10rem;
      padding: 0;
      margin-top: 1rem;
      border: 0.15rem solid #d1d5e9;
      border-radius: 0.5rem;
      box-shadow: 0px 3px 6px #0000001a;
      background-color: #ffffff;
      overflow: hidden;
      position: absolute;
      right: 0;
      display: none;

      li {
        padding: 0;
        margin: 0 0 0.1rem;
        list-style: none;

        a {
          margin: 0;
          padding: 0.5rem 1.5rem;
          font-family: "Mulish", sans-serif;
          font-size: 1.4rem;
          font-weight: normal;
          color: #4b4747 !important;
          cursor: pointer;
          display: block;

          &:hover {
            text-decoration: none;
            color: #4b4747;
          }
        }
      }
    }
  }

  &--show {
    .user__options ul {
      display: block;
    }
  }
}

.bell {
  margin: 0 auto;
  position: relative;
  z-index: 10;

  &__icon {
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    margin: 0 auto;
    padding: 0;
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    svg {
      font-size: 2rem;
      color: #171e44;
      pointer-events: none;
    }

    span {
      padding: 0 0.4rem;
      border-radius: 3rem;
      background-color: #171e44;
      font-family: "Mulish", sans-serif;
      font-size: 1rem;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      text-transform: uppercase;
      position: absolute;
      top: 2rem;
      left: 1.5rem;
    }
  }

  &__notifications {
    // min-width: 25rem;
    max-height: 28.6rem;
    border: 0.1rem solid #d1d5e9;
    border-radius: 0.5rem;
    background-color: #ffffff;
    position: absolute;
    top: 4rem;
    right: 0;
    overflow: auto;
    box-shadow: 0rem 0.3rem 0.6rem #00000029;

    p {
      width: 30rem;
      margin: 0;
      padding: 1rem 2rem;
      font-family: "Mulish", sans-serif;
      font-size: 1.2rem;
      font-weight: normal;
      color: #171e44;
      text-align: left;
      overflow-wrap: break-word;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 2;
      // overflow: hidden;

      &:hover {
        background-color: #f5f9ff;
      }
    }
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    margin: 0 0.5rem;

    &__icon {
      margin: 0;
    }

    & ~ .user {
      margin-left: 1rem;
    }
  }
}

.contenedor-recomendaciones {
  button {
    width: auto;
    height: auto;
    padding: 0.2rem 1rem;
    border: 0;
    border-radius: 0.5rem 0.5rem 0 0;
    background: #171e44;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: 0 0;
    transform: rotate(270deg);
    position: fixed;
    top: 50vh;
    right: -13.9rem;

    p {
      padding: 0;
      margin: 0 0 0 0.5rem;
      font-family: "Mulish", sans-serif;
      font-size: 1.4rem;
      color: #ffffff;
      font-weight: 600;
    }

    img {
      width: 2rem;
      height: auto;
    }
  }
}
/**
.button--primary {
  width: max-content;
  min-width: 15rem;
  height: 4rem;
  padding: 1rem;
  border: 0;
  margin: 0;
  border-radius: 3.2rem;
  background-color: white;
  font-family: "Quicksand", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #006DF0;
  line-height: 2rem;
  text-align: center;
  display: block;
  position: relative;

  .point {
      width: 1.2rem;
      min-width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background-color: white;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      transition: all 0.3s;
  }

  &:hover {
      box-shadow: 0px 4px 10px #FFFFFF4D;

      .point {
          top: -0.8rem;
          right: -0.5rem;
      }
  }
}

.button--secundary {
  width: max-content;
  min-width: 15rem;
  height: 4rem;
  padding: 1rem;
  border: 0;
  margin: 0;
  border-radius: 3.2rem;
  background-color: #006DF0;
  font-family: "Quicksand", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  line-height: 2rem;
  text-align: center;
  display: block;
  position: relative;

  .point {
      width: 1.2rem;
      min-width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background-color: #006DF0;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      transition: all 0.3s;
  }

  &:hover {
      box-shadow: 0px 4px 10px #006DF04D;

      .point {
          top: -0.8rem;
          right: -0.5rem;
      }
  }
}**/
</style>
