<template>
  <section class="contact" id="contact">
    <b-container fluid>
      <b-row class="p-0 m-0">
        <b-col class="d-none d-md-block mt-5 text-right" md="5">
          <span class="title">
            {{ title[$props.lang] }}
          </span>
          <div class="subtitle">
            {{ subtitle[$props.lang] }}
          </div>
          <p class="description">
            {{ description[$props.lang] }}
          </p>
          <div class="others">
            {{ others[$props.lang] }}
          </div>
          <ButtonMeetingComponent :lang="$props.lang" variant="danger" text="Agenda una demostración"/>
        </b-col>
        <b-col class='text-center' sm="12"  md="7">
          <div style="margin:auto;max-width:500px;">
            <FormContactComponent :lang="$props.lang"/>
          </div>
        </b-col>
      </b-row>
      </b-container>
  </section>
</template>

<script>
import FormContactComponent from '../contacts/FormContactComponent.vue'
import ButtonMeetingComponent from "../buttons/ButtonMeetingComponent.vue";
export default {
  name: "ContactComponent",
  props: {
    lang: String
  },
  beforeMount(){
    if (this.lang === undefined){
      this.lang='ES'
    }
    else {
      this.lang = this.lang.toUpperCase()
    }
  },
  components: {
    ButtonMeetingComponent,
    FormContactComponent
  },
  data: function () {
    return {
      title: { ES: "Contacta con nosotros", EN: "Contact us"},
      subtitle: { ES: "¿Cómo podemos ayudarte?", EN: "How can we help you?"},
      description:
        {
          ES: "Estamos para ayudarte y cumplir con tu necesidad en el menor tiempo posible.",
          EN: "We are here to help you and fulfill your needs in the shortest time possible."
        },
      others: {
        ES: "Otras formas de contactarnos:",
        EN: "Other ways to contact us:"
      },
      buttons: [
        {
          text: {
            ES: "Cotizar",
            EN:"Quotes"
          },
          class: "button--secundary",
          link: "/support/quote" },
        {
          text: {
            ES: "Contáctanos",
            EN:"Contact Us"
          },
          class: "button--secundary",
          link: "/support/registro",
        },
        {
          text: {
            ES: "Soporte",
            EN: "Support"
          },
          class: "button--secundary",
          link: "/support" },
      ],

      whatsapp: {
        text: "+1 (314) 988-3020",
        class: "button--whatsapp",
        link: "",
      },

      email: {
        text: "hello@systemico.net",
        class: "button--black",
        link: "",
      }
    };
  },
  methods: {
    funcioRedirect: function (link) {
      window.location = link;
    }
  }
};
</script>
<style lang="scss" scoped>
  .contact{
    background:#dddddd;
    padding:0;
    margin:0;
    .title{
      font-size:1.2em;
      color:#fefefe;
      background:#222222;
      border-radius:0.5rem;
      padding:0.5rem;
    }
    .subtitle{
      margin-top:2rem;
      font-size:3em;
      color:#444444;
      line-height: 3.5rem;
      font-weight: bold;
      padding-bottom:0.5rem;
      margin-bottom:0.5rem;
      border-bottom:3px solid #111111;
    }
    .description{
      font-size: 1.5rem;
      line-height: 2rem;
    }
    .others{
      font-size: 1.5rem;
      color:#444444;
      margin-top:1rem;
      margin-bottom:1rem;
      font-weight: bold;
    }
  }
</style>